<template>
  <div :class="`not-found ${getCurrentLangs.lang}`" data-view>
    <Header></Header>
    <scrollAnalytics></scrollAnalytics>


    <div class="not-found__center">

      <div class="not-found__left-text" v-html="$t('not-found.left-text')"></div>

      <svg viewBox="0 0 697 660" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M131.161 537.118V585.68L173.216 609.961L215.272 585.68V482.701" stroke="#354F57" stroke-width="2"/>
        <path d="M214.975 537.245L173.258 561.337L131.12 537.245" stroke="#354F57" stroke-width="2"/>
        <path d="M173.098 609.319V513.837" stroke="#354F57" stroke-width="2"/>
        <path d="M131.162 585.502V634.063L173.218 658.344L215.273 634.063V585.502" stroke="#354F57" stroke-width="2"/>
        <path d="M214.977 585.626L173.259 609.718L131.97 586.054" stroke="#354F57" stroke-width="2"/>
        <path d="M173.099 657.701V609.883" stroke="#354F57" stroke-width="2"/>
        <path d="M131.162 391.965V442.424L173.218 466.704L215.273 440.526V391.965" stroke="#354F57" stroke-width="2"/>
        <path d="M173.099 466.062V414.45" stroke="#354F57" stroke-width="2"/>
        <path d="M173.988 514.26L215.273 490.808V439.401" stroke="#354F57" stroke-width="2"/>
        <path d="M131.162 536.733L173.218 561.014L215.273 536.733L173.218 514.261" stroke="#354F57" stroke-width="2"/>
        <path d="M131.162 343.967V391.58L173.218 415.861L215.273 391.58V343.967" stroke="#354F57" stroke-width="2"/>
        <path d="M173.099 416.166V368.349" stroke="#354F57" stroke-width="2"/>
        <path d="M7.21906 418.843V467.405L48.7498 490.163M89.5823 467.405V418.843" stroke="#354F57" stroke-width="2"/>
        <path d="M7.21906 466.664V514.277L48.4007 538.558L89.5823 514.277V466.664" stroke="#354F57" stroke-width="2"/>
        <path d="M48.285 491.042V443.225" stroke="#354F57" stroke-width="2"/>
        <path d="M48.285 538.863V491.046" stroke="#354F57" stroke-width="2"/>
        <path d="M48.4657 489.987L89.6743 465.706L130.883 489.987V538.549L89.6743 562.829" stroke="#354F57" stroke-width="2"/>
        <path d="M130.593 490.114L89.7155 514.206" stroke="#354F57" stroke-width="2"/>
        <path d="M89.5582 562.187V514.37" stroke="#354F57" stroke-width="2"/>
        <path d="M7.21621 513.997V562.559L48.4249 586.839L89.6335 562.559V513.997" stroke="#354F57" stroke-width="2"/>
        <path d="M89.3434 514.124L48.4661 538.216L7.1761 514.124" stroke="#354F57" stroke-width="2"/>
        <path d="M48.3087 586.197V538.38" stroke="#354F57" stroke-width="2"/>
        <path d="M173.24 269.165L131.157 293.961V343.553L173.24 368.348L215.323 343.553V293.961L173.24 269.165Z" stroke="#354F57" stroke-width="2"/>
        <path d="M215.027 294.09L173.283 318.693L131.117 294.09" stroke="#354F57" stroke-width="2"/>
        <path d="M173.122 367.693V318.861" stroke="#354F57" stroke-width="2"/>
        <path d="M48.4235 343.93L7.21483 368.726V418.317L48.4235 443.113L89.6321 418.317V368.726L48.4235 343.93Z" stroke="#354F57" stroke-width="2"/>
        <path d="M89.3416 368.855L48.4643 393.458L7.17435 368.855" stroke="#354F57" stroke-width="2"/>
        <path d="M48.3069 442.458V393.626" stroke="#354F57" stroke-width="2"/>
        <path d="M131.473 441.683L89.4172 465.964M131.473 538.806L152.5 526.666L173.528 514.525V465.964" stroke="#354F57" stroke-width="2"/>
        <path d="M173.232 466.091L131.514 490.183L89.3758 466.091" stroke="#354F57" stroke-width="2"/>
        <path d="M605.489 269.609V318.17L647.544 342.451L689.6 318.17V215.191" stroke="#29F6D9" stroke-width="2"/>
        <path d="M689.303 269.735L647.586 293.827L605.447 269.735" stroke="#29F6D9" stroke-width="2"/>
        <path d="M647.426 341.809V246.328" stroke="#29F6D9" stroke-width="2"/>
        <path d="M605.49 317.993V366.554L647.545 390.835L689.601 366.554V317.993" stroke="#29F6D9" stroke-width="2"/>
        <path d="M689.304 318.118L647.587 342.21L606.298 318.546" stroke="#29F6D9" stroke-width="2"/>
        <path d="M647.427 390.192V342.375" stroke="#29F6D9" stroke-width="2"/>
        <path d="M605.49 124.456V174.915L647.546 199.196L689.601 173.017V124.456" stroke="#29F6D9" stroke-width="2"/>
        <path d="M647.427 198.554V146.942" stroke="#29F6D9" stroke-width="2"/>
        <path d="M648.316 246.752L689.601 223.3V171.892" stroke="#29F6D9" stroke-width="2"/>
        <path d="M605.49 269.223L647.546 293.504L689.601 269.223L647.546 246.751" stroke="#29F6D9" stroke-width="2"/>
        <path d="M605.49 76.4564V124.069L647.546 148.35L689.601 124.069V76.4564" stroke="#29F6D9" stroke-width="2"/>
        <path d="M647.427 148.655V100.838" stroke="#29F6D9" stroke-width="2"/>
        <path d="M481.545 151.334V199.896L523.076 222.654M563.908 199.896V151.334" stroke="#29F6D9" stroke-width="2"/>
        <path d="M481.545 199.155V246.768L522.727 271.049L563.908 246.768V199.155" stroke="#29F6D9" stroke-width="2"/>
        <path d="M522.611 223.533V175.716" stroke="#29F6D9" stroke-width="2"/>
        <path d="M522.611 271.354V223.537" stroke="#29F6D9" stroke-width="2"/>
        <path d="M522.79 222.478L563.999 198.198L605.207 222.478V271.04L563.999 295.321" stroke="#29F6D9" stroke-width="2"/>
        <path d="M604.917 222.605L564.04 246.697" stroke="#29F6D9" stroke-width="2"/>
        <path d="M563.882 294.679V246.861" stroke="#29F6D9" stroke-width="2"/>
        <path d="M481.542 246.488V295.05L522.751 319.331L563.96 295.05V246.488" stroke="#29F6D9" stroke-width="2"/>
        <path d="M563.67 246.615L522.792 270.707L481.502 246.615" stroke="#29F6D9" stroke-width="2"/>
        <path d="M522.635 318.688V270.871" stroke="#29F6D9" stroke-width="2"/>
        <path d="M647.567 1.65625L605.484 26.4521V76.0437L647.567 100.84L689.65 76.0437V26.4521L647.567 1.65625Z" stroke="#29F6D9" stroke-width="2"/>
        <path d="M689.353 26.5816L647.609 51.1847L605.443 26.5816" stroke="#29F6D9" stroke-width="2"/>
        <path d="M647.448 100.184V51.3522" stroke="#29F6D9" stroke-width="2"/>
        <path d="M522.75 76.4212L481.541 101.217V150.809L522.75 175.605L563.958 150.809V101.217L522.75 76.4212Z" stroke="#29F6D9" stroke-width="2"/>
        <path d="M563.668 101.347L522.791 125.95L481.501 101.347" stroke="#29F6D9" stroke-width="2"/>
        <path d="M522.633 174.949V126.117" stroke="#29F6D9" stroke-width="2"/>
        <path d="M605.801 174.174L563.745 198.455M605.801 271.297L626.829 259.157L647.856 247.017V198.455" stroke="#29F6D9" stroke-width="2"/>
        <path d="M647.56 198.582L605.842 222.674L563.704 198.582" stroke="#29F6D9" stroke-width="2"/>
        <path d="M373.763 401.492V451.951L415.818 476.232L457.874 450.054V401.492" stroke="#92A1AE" stroke-width="2"/>
        <path d="M415.7 475.59V423.978" stroke="#92A1AE" stroke-width="2"/>
        <path d="M416.589 523.789L457.875 500.337V448.929" stroke="#92A1AE" stroke-width="2"/>
        <path d="M373.764 305.672V354.234L415.819 378.515L457.875 354.234V305.672" stroke="#92A1AE" stroke-width="2"/>
        <path d="M373.764 353.493V401.106L415.819 425.387L457.875 401.106V353.493" stroke="#92A1AE" stroke-width="2"/>
        <path d="M415.701 377.871V330.054" stroke="#92A1AE" stroke-width="2"/>
        <path d="M415.701 425.692V377.875" stroke="#92A1AE" stroke-width="2"/>
        <path d="M373.764 258.595L415.819 282.876L457.875 258.595V210.034" stroke="#92A1AE" stroke-width="2"/>
        <path d="M373.764 257.855V305.468L415.819 329.748L457.875 305.468V257.855" stroke="#92A1AE" stroke-width="2"/>
        <path d="M415.701 282.233V234.415" stroke="#92A1AE" stroke-width="2"/>
        <path d="M415.701 330.054V282.236" stroke="#92A1AE" stroke-width="2"/>
        <path d="M249.823 428.371V476.933L291.353 499.691M332.186 476.933V428.371" stroke="#92A1AE" stroke-width="2"/>
        <path d="M249.823 379.91V428.472L291.004 452.752L332.186 428.472V379.91" stroke="#92A1AE" stroke-width="2"/>
        <path d="M249.823 476.192V523.805L291.004 548.086L332.186 523.805V476.192" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.888 500.569V452.752" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.888 452.109V404.292" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.888 548.391V500.574" stroke="#92A1AE" stroke-width="2"/>
        <path d="M249.823 332.732V381.293L291.353 404.051L332.186 381.293V332.732" stroke="#92A1AE" stroke-width="2"/>
        <path d="M249.823 284.271V332.833L291.004 357.114L332.186 332.833V284.271" stroke="#92A1AE" stroke-width="2"/>
        <path d="M249.823 380.553V428.166L291.004 452.447L332.186 428.166V380.553" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.888 404.931V357.113" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.888 356.47V308.653" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.888 452.752V404.935" stroke="#92A1AE" stroke-width="2"/>
        <path d="M291.067 499.514L332.276 475.234L373.484 499.514V548.076L332.276 572.356" stroke="#92A1AE" stroke-width="2"/>
        <path d="M373.194 499.641L332.317 523.733" stroke="#92A1AE" stroke-width="2"/>
        <path d="M332.16 571.714V523.897" stroke="#92A1AE" stroke-width="2"/>
        <path d="M249.818 523.525V572.086L291.026 596.367L332.235 572.086V523.525" stroke="#92A1AE" stroke-width="2"/>
        <path d="M331.945 523.651L291.068 547.743L249.778 523.651" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.91 595.724V547.907" stroke="#92A1AE" stroke-width="2"/>
        <path d="M373.877 160.312L415.96 135.517L458.043 160.312V209.904L415.96 234.7" stroke="#92A1AE" stroke-width="2"/>
        <path d="M457.746 160.442L416.001 185.045L373.835 160.442" stroke="#92A1AE" stroke-width="2"/>
        <path d="M415.841 234.044V185.213" stroke="#92A1AE" stroke-width="2"/>
        <path d="M331.683 185.239L373.766 160.443L415.849 185.239V234.831L373.766 259.626" stroke="#92A1AE" stroke-width="2"/>
        <path d="M415.552 185.368L373.808 209.972L331.641 185.368" stroke="#92A1AE" stroke-width="2"/>
        <path d="M373.647 258.971V210.139" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.212 210.163L332.295 185.367L374.378 210.163V259.755L332.295 284.55" stroke="#92A1AE" stroke-width="2"/>
        <path d="M374.082 210.293L332.337 234.896L290.171 210.293" stroke="#92A1AE" stroke-width="2"/>
        <path d="M332.177 283.895V235.063" stroke="#92A1AE" stroke-width="2"/>
        <path d="M291.021 210.034L249.812 234.83V284.421L291.021 309.217L332.23 284.421V234.83L291.021 210.034Z" stroke="#92A1AE" stroke-width="2"/>
        <path d="M331.939 234.959L291.062 259.562L249.772 234.959" stroke="#92A1AE" stroke-width="2"/>
        <path d="M290.905 308.561V259.73" stroke="#92A1AE" stroke-width="2"/>
        <path d="M374.074 451.211L332.019 475.492M374.074 548.334L395.102 536.193L416.13 524.053V475.492" stroke="#92A1AE" stroke-width="2"/>
        <path d="M415.833 475.618L374.116 499.71L331.977 475.618" stroke="#92A1AE" stroke-width="2"/>
      </svg>

      <router-link to="/" class="not-found__right-text biggest-cursor-hover" v-html="$t('not-found.right-text')"></router-link>
      <img class="not-found__gradients" src="@/assets/img/main-page/Ellipse-1.svg">
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import {mapGetters} from 'vuex';

export default {
  name: "not-found",
  components: {
    Header,
  },
  computed: {
    ...mapGetters(['getCurrentLangs'])
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/style/animation.scss";

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;

  &.ua {
    .not-found__left-text {
      top: -60px;

      @media screen and (max-width: 425px) {
        top: -20px;
      }
    }
  }

  &__gradients {
    position: fixed;
    top: -40%;
    left: -15%;
    max-width: 1000px;
    object-fit: contain;
    pointer-events: none;
    z-index: 0;
    animation: rotateGradient1 20s infinite linear;

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
  }

  &__center {

    margin: 160px 45px 0 45px;
    display: flex;
    justify-content: center;
    position: relative;

    max-width: 1104px;
    width: 100%;

    @media screen and (max-width: 768px) {
      margin: 140px 45px 0 45px;

      flex-direction: column;
    }

    @media screen and (max-width: 425px) {
      margin: 92px 20px 0 20px;
    }

    svg {
      max-width: 70vh;
      width: 100%;
      height: auto;
      display: block;

      @media screen and (max-width: 768px) {
        max-width: 40vh;
        margin: -5% auto 0 auto;
      }
    }
  }

  &__left-text {
    position: absolute;
    left: 0;
    top: 0;

    font-weight: 300;
    font-size: 80px;
    line-height: 108px;

    color: var(--text-secondary-color);
    text-align: left;

    @media screen and (max-width: 1100px) {
      font-size: 60px;
      line-height: 80px;
    }

    @media screen and (max-width: 768px) {
      position: relative;
      font-size: 36px;
      line-height: 40px;
    }

    ::v-deep span {
      font-weight: 400;
      font-size: 108px;
      line-height: 108px;
      color: var(--text-primary-color-case);

      @media screen and (max-width: 1100px) {
        font-size: 80px;
        line-height: 80px;
      }

      @media screen and (max-width: 768px) {
        font-size: 44px;
        line-height: 44px;
      }

      font-family: "TimesNewRoman-I";
      font-style: italic;
    }
  }

  &__right-text {
    position: absolute;
    right: 0;
    bottom: 10%;

    font-weight: 300;
    font-size: 50px;
    line-height: 52px;

    text-align: left;

    color: var(--text-secondary-color);

    span {
      text-decoration: underline;
    }

    @media screen and (max-width: 1100px) {
      font-size: 40px;
      line-height: 40px;
    }

    @media screen and (max-width: 768px) {
      position: relative;
      text-align: right;
      font-size: 36px;
      line-height: 40px;
      margin-top: 30px;

    }

    @media screen and (min-width: 1023px) {

      &:hover {
        ::v-deep span {
          &:before {
            width: 20%;
          }
        }
      }
    }

    &:active {
      ::v-deep span {
        color: #29F6D9;

        &:before {
          width: 20%;
          background: #29F6D9;

        }
      }
    }

    ::v-deep span {
      font-family: "TimesNewRoman-I";
      font-style: italic;

      font-weight: 400;
      font-size: 66px;
      line-height: 64px;
      position: relative;

      color: var(--text-primary-color-case);
      transition: all .2s ease;

      @media screen and (max-width: 1100px) {
        font-size: 50px;
        line-height: 50px;
      }

      @media screen and (max-width: 768px) {
        font-size: 44px;
        line-height: 44px;
      }

      &:before {
        transition: all .2s ease;
        content: '';
        position: absolute;
        left: 0;
        bottom: 4px;
        width: 100%;
        height: 1px;

        background: var(--text-primary-color-case);
      }
    }
  }
}

</style>
